<script>
  import { writable } from 'svelte/store'
  import Caret from '../../../components/Caret.svelte'

  export let fullWidthHeader = true
  export let chevronSize = 1.5
  export let open = false
  export let noChevron = false
  export let headerTarget = true
  export let disableInnerToggle = false

  const isOpen = writable(open)

  function toggleAccordion() {
    isOpen.update((value) => !value)
  }
</script>

<style>
  .accordion-body {
    transition: all 0.2s ease-out;
  }

  .open {
    max-height: 3000px;
  }
</style>

<div class="accordion-container">
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  {#if headerTarget}
    <div
      class="cursor-pointer items-center"
      class:flex={!noChevron}
      class:justify-between={fullWidthHeader}
      on:click={toggleAccordion}
      on:keypress={toggleAccordion}
    >
      <slot name="header" />
      {#if !noChevron}
        <div class="px-2">
          <Caret
            direction={disableInnerToggle ? open : $isOpen ? 'up' : 'down'}
            scale={chevronSize}
          />
        </div>
      {/if}
    </div>
  {:else}
    <div
      class="cursor-pointer items-center"
      class:flex={!noChevron}
      class:justify-between={fullWidthHeader}
    >
      <slot name="header" />
      <div
        class="px-2"
        on:click={toggleAccordion}
        on:keypress={toggleAccordion}
      >
        <Caret
          direction={disableInnerToggle ? open : $isOpen ? 'up' : 'down'}
          scale={chevronSize}
        />
      </div>
    </div>
  {/if}
  <div
    class="accordion-body overflow-hidden max-h-0 text-left"
    class:open={disableInnerToggle ? open : $isOpen}
  >
    <slot name="body" />
  </div>
</div>
